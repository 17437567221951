export default {
  form1: {
    p1: {
      widget: 'wtext',
      data: null,
      col: 6,
      label: 'Nama'
    },
    p2: {
      widget: 'wtext',
      data: null,
      col: 2,
      label: 'No Rekam Medis',
      property: { attrs: { type: 'number' } }
    },
    p3: {
      widget: 'wtext',
      data: null,
      col: 4,
      label: 'NIK',
      property: { attrs: { type: 'number' } }
    },
    p4: {
      widget: 'wtext',
      data: null,
      col: 4,
      label: 'No identitas lain'
    },
    p5: {
      widget: 'wtext',
      data: null,
      col: 3,
      label: 'Tempat Lahir'
    },
    p6: {
      widget: 'wdatenormal',
      data: null,
      col: 2,
      label: 'Tanggal Lahir'
    },
    p7: {
      widget: 'wradio',
      data: [
        { text: 'Laki-Laki', value: 'Laki-Laki' },
        { text: 'Perempuan', value: 'Perempuan' }
      ],
      col: 3,
      label: ' '
    },
    p8: {
      widget: 'wtext',
      data: null,
      col: 6,
      label: 'Nama Penjamin'
    },
    p9: {
      widget: 'wtext',
      data: null,
      col: 6,
      label: 'Nomor telphone',
      property: { attrs: { type: 'number' } }
    },
    p10: {
      widget: 'wtext',
      data: null,
      col: 6,
      label: 'Ruangan / kelas/ poli'
    },
    p11: {
      widget: 'wtext',
      data: null,
      col: 6,
      label: 'DPJP'
    }
  },
  form2: {
    p1: {
      widget: 'wdatenormal',
      data: null,
      col: 6,
      label: 'Tanggal'
    },
    p2: {
      widget: 'wtext',
      data: null,
      col: 6,
      label: 'Waktu'
    }
  },
  form3: {
    p1: {
      widget: 'wdatenormal',
      data: null,
      col: 6,
      label: 'Tanggal discharge administrasi'
    },
    p2: {
      widget: 'wtext',
      data: null,
      col: 6,
      label: 'Jam discharge administrasi'
    }
  },
  form4: {
    p1: {
      widget: 'wtextarea',
      data: null,
      col: 6,
      label: 'Diagnosis awal atau masuk'
    },
    p2: {
      widget: 'wtextarea',
      data: null,
      col: 6,
      label: 'Diagnosis akhir atau keluar'
    },
    p3: {
      widget: 'wtextarea',
      data: null,
      col: 6,
      label: 'Diagnosis primer atau utama'
    },
    p4: {
      widget: 'wtextarea',
      data: null,
      col: 6,
      label: 'Diagnosis sekunder atau penyerta'
    }
  },
  form5: {
    p1: {
      widget: 'wtext',
      data: null,
      col: 4,
      label: 'Berat Badan',
      property: { attrs: { suffix: 'Kg', type: 'number' } }
    },
    p2: {
      widget: 'wtext',
      data: null,
      col: 4,
      label: 'Panjang Badan',
      property: { attrs: { psuffix: 'Cm', type: 'number' } }
    },
    p3: {
      widget: 'wtext',
      data: null,
      col: 4,
      label: 'Tinggi Badan',
      property: { attrs: { suffix: 'Cm', type: 'number' } }
    }
  },
  form5a: {
    p1: {
      title: 'a. BB/U',
      widget: 'wradio',
      data: [
        { text: '<-3 SD (Gizi Buruk)', value: '<-3 SD (Gizi Buruk)' },
        { text: '-3 SD sampai dengan <-2 SD (Gizi Kurang)', value: '-3 SD sampai dengan <-2 SD (Gizi Kurang)' },
        { text: '-2 SD Sampai dengan 2 SD (Gizi Baik)', value: '-2 SD Sampai dengan 2 SD (Gizi Baik)' },
        { text: '2 SD (Gizi Lebih)', value: '2 SD (Gizi Lebih)' }
      ],
      col: 6
    },
    p2: {
      title: 'b. PB/U',
      widget: 'wradio',
      data: [
        { text: '<-3 SD (Sangat Pendek)', value: '<-3 SD (Sangat Pendek)' },
        { text: '-3 SD sampai dengan <-2 SD (Pendek)', value: '-3 SD sampai dengan <-2 SD (Pendek)' },
        { text: '-2 SD Sampai dengan 2 SD (Normal)', value: '-2 SD Sampai dengan 2 SD (Normal)' },
        { text: '2 SD (Tinggi', value: '2 SD (Tinggi)' }
      ],
      col: 6
    },
    p3: {
      title: 'c. TB/U',
      widget: 'wradio',
      data: [
        { text: '<-3 SD (Sangat Pendek)', value: '<-3 SD (Sangat Pendek)' },
        { text: '-3 SD sampai dengan <-2 SD (Pendek)', value: '-3 SD sampai dengan <-2 SD (Pendek)' },
        { text: '-2 SD Sampai dengan 2 SD (Normal)', value: '-2 SD Sampai dengan 2 SD (Normal)' },
        { text: '2 SD (Tinggi', value: '2 SD (Tinggi)' }
      ],
      col: 6
    },
    p4: {
      title: 'd. BB/TB',
      widget: 'wradio',
      data: [
        { text: '<-3 SD (Sangat kurus)', value: '<-3 SD (Sangat kurus)' },
        { text: '-3 SD sampai dengan <-2 SD (kurus)', value: '-3 SD sampai dengan <-2 SD (kurus)' },
        { text: '-2 SD Sampai dengan 2 SD (Normal)', value: '-2 SD Sampai dengan 2 SD (Normal)' },
        { text: '2 SD (Gemuk)', value: '2 SD (Gemuk)' }
      ],
      col: 6
    },
    p5: {
      title: 'e. IMT/U',
      widget: 'wradio',
      data: [
        { text: '<-3 SD (Sangat kurus)', value: '<-3 SD (Sangat kurus)' },
        { text: '-3 SD sampai dengan <-2 SD (kurus)', value: '-3 SD sampai dengan <-2 SD (kurus)' },
        { text: '-2 SD Sampai dengan 1 SD (normal)', value: '-2 SD Sampai dengan 1 SD (normal)' },
        { text: '>1 SD Sampai dengan 2 SD (gemuk)', value: '>1 SD Sampai dengan 2 SD (gemuk)' },
        { text: '>2 SD (obesitas)', value: '>2 SD (obesitas)' }
      ],
      col: 6
    }
  },
  form5b: {
    p1: {
      label: 'BB naik adekuat',
      widget: 'wradio',
      data: [
        { text: 'Ya', value: 'Ya' },
        { text: 'Tidak', value: 'Tidak' }
      ],
      col: 12
    }
  },
  form6: {
    p1: {
      label: ' ',
      widget: 'wradio',
      data: [
        { text: 'Ya', value: 'Ya' },
        { text: 'Tidak', value: 'Tidak' }
      ],
      col: 12
    }
  },
  form7: {
    p1: {
      label: ' ',
      widget: 'wradio',
      data: [
        { text: 'Ya', value: 'Ya' },
        { text: 'Tidak', value: 'Tidak' }
      ],
      col: 12
    }
  },
  form8: {
    p1: {
      label: ' ',
      widget: 'wradio',
      data: [
        { text: 'Ya', value: 'Ya' },
        { text: 'Tidak', value: 'Tidak' }
      ],
      col: 12
    }
  },
  form9: {
    p1: {
      label: ' ',
      widget: 'wtextarea',
      col: 12
    }
  }
}
